import NavBar from '../../../Components/NavBar'
import { AuthContextApi } from '../../../Context/api'
import { AuthContext } from '../../../Context'
import { useContext, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { MagnifyingGlassCircleIcon } from '@heroicons/react/24/outline'
import Footer from '../../../Components/Footer'
import SpinnerOverlay from '../../../Components/SpinnerOverlay'
import moment from 'moment'
import NavMenu from '../../../Components/NavMenu/juridico'


const Juridico = () => {
    const { checkLogin, getJuridico } = useContext(AuthContextApi)
    const { states, setters } = useContext(AuthContext)
    const hist = useNavigate()

    const isSigned = async () => {
        const response = await checkLogin()
        if (response === true) {

            setters.setSigned(true)
        } else {
            setters.setSigned(false)
        }
    }


    const load = async () => {
        await getJuridico()

    }

    const getProcessoPagina = async (id) => {
        hist(`/juridico/processos/${id}`)
    }

    useEffect(() => {
        setters.setLimitSearchJuridico(false)
        setters.setOffsetJuridico(0)
        isSigned()
        if (states.dataJuridico.length === 0) {
            load()
        }

        return () => {
            setters.setDataJuridico([])
            setters.setLimitSearchJuridico(false)
            setters.setOffsetJuridico(0)
        }
    }, [])



    useEffect(() => {
        load()
    }, [states.offsetJuridico])

    return (
        <>
            <div className="flex flex-1 flex-col pb-16 bg-white">
                <NavBar />
                <div className="flex flex-1 items-center justify-center">
                    <SpinnerOverlay />

                    <div className='flex flex-1 flex-col md:pl-64 mb-4 mt-2'>
                        <div className="p-4 border">
                            <NavMenu />
                            <div className="px-4 sm:px-6 lg:px-8">
                                <h1 className="text-[28px] font-semibold leading-6 text-gray-900">Jurídico</h1>
                                <p className="mt-2 text-lg text-gray-700">
                                    Olá! Abaixo estão listados os números dos seus processos.
                                </p>
                                <br />
                                <p className="mt-2 text-lg text-gray-700">
                                    Para acompanhar o andamento do seu processo,  acesse o Informativo Jurídico em nosso site: <Link to='/juridico/informativo' className='text-blue-500 hover:underline'
                                    > Clique aqui para acessar o informativo</Link>
                                </p>
                            </div>
                        </div>
                        <div className="p-4 border">
                            <div className="px-4 sm:px-6 lg:px-8">
                                <h1 className="text-[24px] text-base font-semibold leading-6 text-gray-900">Processos</h1>
                                <p className="mt-2 text-sm text-gray-700">
                                    {
                                        states.dataJuridico.length > 0 ? states.dataJuridico.map((item, index) => {
                                            console.log(item)
                                            return (
                                                <>
                                                    <div key={index} className="border p-4 my-2 flex flex-col sm:flex-row rounded-[10px] hover:shadow sm:items-between sm:justify-between">
                                                        <div className="flex flex-col ">
                                                            <p className="text-sm text-gray-700 mb-4">Nome da ação: {item.action_name}</p>
                                                            <div className="flex flex-row">
                                                                <div className="flex flex-col">
                                                                    <p className="text-sm text-gray-700">Número do processo: {item.number}</p>
                                                                    <p className="text-sm text-gray-700">Título do processo: {item.caseTitle}</p>
                                                                    <p className="text-sm text-gray-700">Atualizado em: {moment(item.process_updated_at).format('DD/MM/YYYY')}</p>
                                                                </div>
                                                                <div className="flex flex-col ml-12">
                                                                    <p className="text-sm text-gray-700">Nome do tribunal: {item.court}</p>
                                                                    <p className="text-sm text-gray-700">Vara: {item.vara}</p>
                                                                    <p className="text-sm text-gray-700">{item.lawFirmName ? "Nome do escritório de advocacia:" : item.lawyerName ? "Nome do advogado:" : "Nome do escritório:"}

                                                                        {item.lawFirmName ? item.lawFirmName : item.lawyerName ? item.lawyerName : "Não informado"}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {
                                                            item.haveUpdates ?
                                                                <button className="flex items-center justify-center " onClick={() => getProcessoPagina(item.process_id)}>
                                                                    <span className="bg-blue-500 text-white p-2 rounded mt-2 flex  hover:shadow-lg">
                                                                        <MagnifyingGlassCircleIcon className="h-5 w-5 mr-2" />
                                                                        <p>Ver atualizações</p>
                                                                    </span>
                                                                </button> :
                                                                <div className="flex items-center justify-center ">
                                                                    <span className="bg-gray-500 text-white p-2 rounded mt-2 flex  hover:shadow-lg">
                                                                        <p>Sem atualizações</p>
                                                                    </span>
                                                                </div>
                                                        }
                                                    </div>
                                                </>
                                            )
                                        }) : <p className="text-sm text-gray-700">Nenhum processo encontrado</p>
                                    }
                                </p >
                                {
                                    (states.limitSearchJuridico === false && states.dataJuridico.length >= states.limitJuridico) && states.dataJuridico.length > 0 && <button onClick={() => setters.setOffsetJuridico(states.offsetJuridico + states.limitJuridico)} className="bg-blue-500 text-white p-2 rounded mt-2">Carregar mais</button>
                                }
                            </div>
                        </div>




                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}


export default Juridico
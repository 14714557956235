import NavBar from '../../../Components/NavBar'
import { AuthContextApi } from '../../../Context/api'
import { AuthContext } from '../../../Context'
import { useContext, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { MagnifyingGlassCircleIcon } from '@heroicons/react/24/outline'
import Footer from '../../../Components/Footer'
import SpinnerOverlay from '../../../Components/SpinnerOverlay'
import moment from 'moment'


const Juridico = () => {
    const { checkLogin, getJuridicoProcesso } = useContext(AuthContextApi)
    const { states, setters } = useContext(AuthContext)
    const { id } = useParams()
    const hist = useNavigate()


    const isSigned = async () => {
        const response = await checkLogin()
        if (response === true) {

            setters.setSigned(true)
        } else {
            setters.setSigned(false)
        }
    }
    const dowloadFile = (base64String, filename) => {
        const link = document.createElement("a");
        link.href = `data:application/pdf;base64,${base64String}`;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const load = async () => {
        await getJuridicoProcesso(id)

    }

    const goBack = () => {
        hist(-1)
    }

    const loadMore = () => {
        setters.setOffsetProcessos(states.offsetProcessos + states.limitProcessos)
    }

    // const [dataProcessos, setDataProcessos] = useState({})
    // const [offsetProcessos, setOffsetProcessos] = useState(0)
    // const [limitProcessos, setLimitProcessos] = useState(jsonConfig.limitSearch)
    // const [limitSearchProcessos, setLimitSearchProcessos] = useState(false)

    useEffect(() => {
        setters.setLimitSearchProcessos(false)
        setters.setOffsetProcessos(0)
        isSigned()
        if (Object.keys(states.dataProcessos).length === 0) {
            load()
        }

        return () => {
            setters.setDataProcessos({})
            setters.setLimitSearchProcessos(false)
            setters.setOffsetProcessos(0)
        }
    }, [])

    console.log(states.dataProcessos)


    useEffect(() => {
        load()
    }, [states.offsetProcessos])

    return (
        <>
            <div className="flex flex-1 flex-col pb-16 bg-white">
                <NavBar />
                <div className="flex flex-1 items-center justify-center">
                    <SpinnerOverlay />

                    <div className='flex flex-1 flex-col md:pl-64 mb-4 mt-2 p-4'>

                        <div className="flex items-center justify-between ml-6 mb-6">
                            <button onClick={goBack} className="flex items-center text-gray-700 hover:text-gray-900 border p-3 rounded-[10px] hover:shadow">
                                <span className="ml-2">Voltar</span>
                            </button>
                            {
                                ((states.dataProcessos?.updates?.length > 0 || states.dataProcessos?.motions?.length > 0) && (states.dataProcessos?.updates?.length >= states.limitProcessos || states.dataProcessos?.motions?.length >= states.limitProcessos) && !states.limitSearchProcessos) &&
                                <button onClick={loadMore} className="flex items-center text-gray-700 hover:text-gray-900 border p-3 rounded-[10px] hover:shadow">
                                    <span className="ml-2">Carregar mais dados</span>
                                </button>
                            }
                        </div>

                        <div className="px-4 sm:px-6 lg:px-8 border ml-6 p-4 rounded-[10px] hover:shadow">
                            <h1 className="text-[28px] font-semibold leading-6 text-gray-900">Dados do processo</h1>
                            <p className="mt-2 text-lg text-gray-700">
                                Olá! Abaixo estão listados os dados e as atualizações do seu processo.
                            </p>
                            <br />
                            <div className="flex md:flex-col">
                                <div className="flex flex-col md:flex-row md:items-center justify-between ">
                                    <div className="flex flex-col">
                                        <p className="text-lg text-gray-700">Nome da ação:</p>
                                        <p className="text-lg text-gray-700 font-semibold truncate overflow-ellipsis whitespace-normal max-w-xs md:max-w-sm">{states.dataProcessos.action_name}</p>
                                    </div>
                                    <div className="flex flex-col mt-2 md:mt-0">
                                        <p className="text-lg text-gray-700">Título do processo:</p>
                                        <p className="text-lg text-gray-700 font-semibold">{states.dataProcessos.caseTitle}</p>
                                    </div>
                                    <div className="flex flex-col mt-2 md:mt-0">
                                        <p className="text-lg text-gray-700">Número do processo:</p>
                                        <p className="text-lg text-gray-700 font-semibold">{states.dataProcessos.number}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="px-4 sm:px-6 lg:px-8 border ml-6 p-4 rounded-[10px] hover:shadow mt-4">
                            <h1 className="text-[28px] font-semibold leading-6 text-gray-900">Atualizações do processo</h1>
                            <p className="mt-2 text-lg text-gray-700">
                                Olá! Abaixo estão listados as atualizações do seu processo.
                            </p>
                            <br />
                            {
                                states.dataProcessos?.updates?.length > 0 ? states.dataProcessos.updates.map((item, index) => {
                                    return (
                                        <div className="flex flex-col m-4 p-4 border rounded-lg" key={index}>
                                            {/* Header Section */}
                                            <div className="flex flex-col md:flex-row justify-between mb-4">
                                                <div className="flex flex-col mb-2 md:mb-0 md:w-1/2">
                                                    <p className="text-lg text-gray-700">Título da Atualização:</p>
                                                    <p className="text-lg text-gray-800 font-bold truncate overflow-ellipsis whitespace-normal">{item.title}</p>
                                                </div>
                                                <div className="flex flex-col mb-2 md:mb-0 md:w-1/2 md:text-right">
                                                    <p className="text-lg text-gray-700">Data de criação:</p>
                                                    <p className="text-lg text-gray-800 font-bold">{moment(item.created_at).format('DD/MM/YYYY')}</p>
                                                </div>
                                            </div>

                                            {/* Body Section */}
                                            <div className="flex flex-col mb-4">
                                                <p className="text-lg text-gray-700">Descrição da Atualização:</p>
                                                <p className="text-lg text-gray-800 font-medium whitespace-normal">{item.description}</p>
                                            </div>

                                            {/* File Download Section */}
                                            <div className="flex flex-col md:flex-row justify-between items-center">
                                                <div className="flex flex-col mb-2 md:mb-0 md:w-1/2">
                                                    <p className="text-lg text-gray-700">Download do Arquivo:</p>
                                                    {item.filename !== null ? (
                                                        <button onClick={() => dowloadFile(item.fileBase64, item.filename)} className="text-gray-700 hover:text-gray-900 border p-3 rounded-[10px] hover:shadow">
                                                            <span className="ml-2">Baixar</span>
                                                        </button>
                                                    ) : (
                                                        <p className="text-lg text-gray-800 font-bold">Sem arquivo</p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }) :
                                    <div className="flex flex-col items-center justify-center">
                                        <p className="text-lg text-gray-700">Sem atualizações</p>
                                    </div>
                            }
                        </div>


                        <div className="px-4 sm:px-6 lg:px-8 border ml-6 p-4 rounded-[10px] hover:shadow mt-4">
                            <h1 className="text-[28px] font-semibold leading-6 text-gray-900">Solicitações do processo</h1>
                            <p className="mt-2 text-lg text-gray-700">
                                Olá! Abaixo estão listados as solicitações do seu processo.
                            </p>
                            <br />
                            {
                                states.dataProcessos?.motions?.length > 0 ? states.dataProcessos.motions.map((item, index) => {
                                    return (
                                        <div className="flex flex-col m-4 p-4 border rounded-lg" key={index}>
                                            {/* Header Section */}
                                            <div className="flex flex-col md:flex-row justify-between mb-4">
                                                <div className="flex flex-col mb-2 md:mb-0">
                                                    <p className="text-lg text-gray-700">Tipo de Moção:</p>
                                                    <p className="text-lg text-gray-800 font-bold">{item.motionType}</p>
                                                </div>
                                                <div className="flex flex-col mb-2 md:mb-0">
                                                    <p className="text-lg text-gray-700">Arquivado por:</p>
                                                    <p className="text-lg text-gray-800 font-bold">{item.filedBy}</p>
                                                </div>
                                                <div className="flex flex-col mb-2 md:mb-0">
                                                    <p className="text-lg text-gray-700">Data de Arquivamento:</p>
                                                    <p className="text-lg text-gray-800 font-bold">{moment(item.filedDate).format('DD/MM/YYYY')}</p>
                                                </div>
                                            </div>

                                            {/* Body Section */}
                                            <div className="flex flex-col mb-4">
                                                <p className="text-lg text-gray-700">Descrição:</p>
                                                <p className="text-lg text-gray-800 font-medium whitespace-normal">{item.description}</p>
                                            </div>

                                            {/* Decision Section */}
                                            <div className="flex flex-col md:flex-row justify-between mb-4">
                                                <div className="flex flex-col mb-2 md:mb-0">
                                                    <p className="text-lg text-gray-700">Decisão:</p>
                                                    <p className="text-lg text-gray-800 font-bold">{item.outcome === null ? 'Pendente' : item.outcome === 'defired' ? 'Deferido' : 'Indeferido'}</p>
                                                </div>
                                                <div className="flex flex-col mb-2 md:mb-0">
                                                    <p className="text-lg text-gray-700">Nome do Juiz:</p>
                                                    <p className="text-lg text-gray-800 font-bold">{item.judgeName}</p>
                                                </div>
                                                <div className="flex flex-col mb-2 md:mb-0">
                                                    <p className="text-lg text-gray-700">Data da Decisão:</p>
                                                    <p className="text-lg text-gray-800 font-bold">{item.decisionDate ? moment(item.decisionDate).format('DD/MM/YYYY') : 'Pendente'}</p>
                                                </div>
                                            </div>

                                            {/* Notes Section */}
                                            <div className="flex flex-col mb-4">
                                                <p className="text-lg text-gray-700">Notas:</p>
                                                <p className="text-lg text-gray-800 font-medium whitespace-normal">{item.notes}</p>
                                            </div>

                                            {/* File Download Section */}
                                            <div className="flex flex-col md:flex-row justify-between items-center">
                                                {item.filename && (
                                                    <div className="flex flex-col mb-2 md:mb-0">
                                                        <p className="text-lg text-gray-700">Download do Arquivo:</p>
                                                        <button onClick={() => dowloadFile(item.fileBase64, item.filename)} className="text-gray-700 hover:text-gray-900 border p-3 rounded-[10px] hover:shadow">
                                                            <span className="ml-2">Baixar</span>
                                                        </button>
                                                    </div>
                                                )}
                                                <div className="flex flex-col">
                                                    <p className="text-lg text-gray-700">Data de Criação:</p>
                                                    <p className="text-lg text-gray-800 font-bold">{moment(item.created_at).format('DD/MM/YYYY')}</p>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }) :
                                    <div className="flex flex-col items-center justify-center">
                                        <p className="text-lg text-gray-700">Sem Solicitações</p>
                                    </div>
                            }



                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}


export default Juridico